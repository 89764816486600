import request from '@/utils/request'

//用户管理--搜索用户
export function getAllUser(data) {
  return request({
    url: '/CustomerController/saveCustomer',
    method: 'post',
    data
  })
}

//会员管理--搜索VIP
export function getAllMember(data) {
  return request({
    url: '/CustomerController/selectMember',
    method: 'post',
    data
  })
}

//会员管理--想联系
export function getContact(data) {
  return request({
    url: '/CustomerController/selectContactById',
    method: 'post',
    data
  })
}

//用户管理--查看详情
export function getUserDetail(data) {
  return request({
    url: '/CustomerController/selectParticulars',
    method: 'get',
    params: data
  })
}

//用户管理--头像显示数据
export function selectPart(data) {
  return request({
    url: '/CustomerController/selectPart',
    method: 'get',
    params: data
  })
}

//用户管理--同意用户的会员申请
export function updateConsent(data) {
  return request({
    url: '/CustomerController/updateConsent',
    method: 'get',
    params: data
  })
}

//用户管理--拒绝用户的会员申请
export function updateRefuse(data) {
  return request({
    url: '/CustomerController/updateRefuse',
    method: 'get',
    params: data
  })
}

//用户管理--查看详情--获取参与活动总数、他关注的总数、关注他的总数、参与项目总数
export function getAllCount(data) {
  return request({
    url: '/CustomerController/selectAllCount',
    method: 'get',
    params: data
  })
}

//用户管理--查看详情--参与活动
export function getActivity(data) {
  return request({
    url: '/CustomerController/selectActivity',
    method: 'post',
    data
  })
}

//用户管理--查看详情--他关注的
export function getAttention(data) {
  return request({
    url: '/CustomerController/selectAttention',
    method: 'post',
    data
  })
}

//用户管理--查看详情--关注他的
export function getBeAttention(data) {
  return request({
    url: '/CustomerController/selectBeAttention',
    method: 'post',
    data
  })
}

//用户管理--查看详情--参与项目
export function getProject(data) {
  return request({
    url: '/CustomerController/selectProject',
    method: 'post',
    data
  })
}

//会员管理--修改星名贴
export function updateCard(data) {
  return request({
    url: '/CustomerController/updateCard',
    method: 'get',
    params: data
  })
}

//会员管理--会员退款
export function userRefund(data) {
  return request({
    url: 'activeAndClubPayOrder/refund',
    method: 'get',
    params: data
  })
}
//改变申请状态
export function updateConsentRefuse(data) {
  return request({
    url: '/CustomerController/updateConsentRefuse',
    method: 'get',
    params: data
  })
}
//新增名帖
export function saveNameCard(data) {
  return request({
    url: '/NameCardController/saveNameCard',
    method: 'post',
    data
  })
}
//关键词提取
export function selectParticulars(data) {
  return request({
    url: '/NameCardController/selectParticulars',
    method: 'post',
    data
  })
}
