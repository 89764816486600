<template>
  <div class="content">
    <div class="line_one">
      <div class="goods_img">
        <img :src="userData.headPortrait" alt="">
      </div>
      <div class="goods_text">
        <div class="tip">
          <div class="black_one">{{userData.userName}}</div>
          <div class="icon_man">
              <img src="../../../../../static/images/jr-icon-man.png" v-if="userData.gender == 2">
              <img src="../../../../../static/images/jr-icon-female.png" v-else>
          </div>
        </div>
        <div class="tip_two">
          <div v-for="(item,index) in userData.label" :key="index">{{item}}</div>
        </div>
        <div class="tip_three">{{userData.personalIntro}}</div>
      </div>
    </div>
    <div class="line_two">
      <div class="state">
        <span>状态:</span>
        <el-button :class="tabIndex==='0'?'active':''" style="margin-left:10px" @click="searchClick('0')" >全部</el-button>
        <el-button :class="tabIndex==='1'?'active':''" @click="searchClick('1')">已联系</el-button>
        <el-button :class="tabIndex==='2'?'active':''" @click="searchClick('2')">未联系</el-button>
      </div>
      <el-table :data="dataUserList" style="width: 100%" class="data_list" v-loading="loading" :header-cell-style="getRowClass">
        <el-table-column label="用户ID" prop="divisionName" align="center" width="80"/>
        <el-table-column label="头像" width="100" align="center">
          <template v-slot="{ row }">
            <div class="flex">
              <img
              :src="'https://imagecharacter.oss-cn-beijing.aliyuncs.com/' + row.logo"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="真实姓名" prop="userName" align="center" width="80"/>
        <el-table-column label="手机号" prop="mobile" align="center" width="80"/>
        <el-table-column label="机构" prop="institution" align="center" width="100"/>
        <el-table-column label="职位" prop="position" align="center"  width="80" />
        <el-table-column label="标签" prop="phone" align="center"  width="120">
         <template v-slot="{ row }">
            <div class="tip_two">
              <div v-for="(item,index) in row.labelList" :key="index">{{item}}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="用户等级" prop="whetherMember" align="center"  width="100"/>
        <el-table-column label="用户简介" prop="personalIntro" align="center"/>
        <el-table-column label="操作" align="center" width="250">
         <template v-slot="{ row }">
            <div v-if="row.whetherContact == 0" class="notContact">未联系</div>
            <div v-if="row.whetherContact == 1" class="contact">已联系</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import { pagination } from '@/mixins/pagination'
import { getUserDetail, getContact } from '@/api/userManagement.js'
const defaultQueryInfo = Object.freeze({
  pageNum: 0,
  pageSize: 10,
  whetherContact:'0'
})
export default {
  mixins: [pagination],
  data () {
    return {
      tabPosition:'',
      userData:[],
      tabIndex:'0',
      dataUserList:[],
      queryInfo: { ...defaultQueryInfo },
      loading:false,
    }
  },
  mounted() {
    this.id = this.$route.query.id
    this.getUserDetail(this.id)
    this.search()
  },
  methods: {
    //按钮选中css
    searchClick(index) {
      this.search(index)
      this.tabIndex = index
    },
    async getUserDetail(id){
      const { data:res } = await getUserDetail({id:id})
      this.userData = res.data
    },
    async search(num) {
      if(num) this.queryInfo.whetherContact = num
      this.loading = true
      const { data: res } = await getContact(this.queryInfo)
      if (res.resultCode === 200) {
        this.dataUserList = res.data.list
        this.total = res.data.total
        this.loading = false
      }
    },  
  },
}
</script> 

<style scoped lang="less">
.active{
  background: #7C71E1;
  color: #ffffff;
  border: none;
}
.content{
  background: #F5F7F9;
  width: 100%;
  .line_one{
    width: 100%;
    background: #ffffff;
    border-radius: 12px;
    margin: 20px 0px 0px 15px;
    display: flex;
    .goods_img{
      width: 100px;
      height: 100px;
      border-radius: 8px;
      margin: 16px 16px 16px 16px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .goods_text{
      margin-top: 16px;
      .tip{
        display: flex;
        .black_one{
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        margin-right: 15px;
        }
        .icon_man{
          width: 16px;
          height: 14px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
      .tip_two{
        display: flex;
        margin-top: 8px;
        div{
          padding: 2px 5px;
          font-size: 12px;
          line-height: 17px;
          color: #7067F2;
          background: #7067F21A;
          margin-right: 8px;
        }
      }
      .tip_three{
        margin-top: 8px;
        width: 800px;
        height: 40px;
        -webkit-line-clamp:2;
        display: -webkit-box;
        -webkit-box-orient:vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  } 
  .line_two{
    background: #ffffff;
    margin-top: 20px;
    overflow: hidden;
    border-radius: 12px;
    margin-left: 15px;
    .state{
      margin: 25px 0px 20px 20px;
    }
    .data_list{
      margin: 0 0 0 20px;
    }
  }
  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
}
.notContact{
  color: #818181;
}
.contact{
  color: #7563FF;
}

.el-button.is-active{
  background: #7C71E1;
  color: #ffffff;
}
.el-button:hover,
.el-button:active {
  background: #7C71E1;
  color: #ffffff;
}
</style>
